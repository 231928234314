import './skip-link-focus-fix';
import 'script-loader!lity';
import 'script-loader!slick-carousel';
import 'script-loader!slick-lightbox';

(function($) {

	$(document).ready(function() {
		menuToggle();
		accordionShortcode();
		fullWidthVideo();
		tabbedContent();
		repeaterGalleryLightbox();
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$(".site-header__menu-wrap").toggleClass('site-header__menu-wrap--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

	// Full width video toggle
	function fullWidthVideo() {
		$('.full-image__overlay').on('click', function(e) {
			e.preventDefault();

			var videoWrap = $(this).next('.full-image__video'),
				video     = videoWrap.find('iframe');

			videoWrap.show();
			video[0].src += "&autoplay=1";
		});
	}

	// Tabbed content block
	function tabbedContent() {
		$('.tabbed-content__tab-toggle').on('click', function(e) {
			e.preventDefault();

			var index   = $(this).data('tab'),
			    toggles = $(this).siblings('.tabbed-content__tab-toggle'),
			    block   = $(this).closest('.tabbed-content'),
				tabs    = block.find('.tabbed-content__tab'),
				thisTab = block.find('.tabbed-content__tab[data-tab="' + index + '"]');

			$(this).addClass('tabbed-content__tab-toggle--active');
			toggles.removeClass('tabbed-content__tab-toggle--active');

			tabs.removeClass('tabbed-content__tab--open');
			thisTab.addClass('tabbed-content__tab--open');
		});
	}

	// Repeater gallery lightbox
	function repeaterGalleryLightbox() {
		var gallery = $('.repeater-gallery__wrap');
		
		gallery.each(function(){
			$(this).slickLightbox({
				itemSelector: '> a',
				caption     : 'caption'
			});
		});
	}

})(jQuery);